import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function JsonIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="M4.75 15H6.5q.425 0 .713-.288T7.5 14V9.75q0-.325-.213-.537T6.75 9t-.537.213T6 9.75v4H5v-.625q0-.275-.175-.45t-.45-.175t-.45.175t-.175.45V14q0 .425.288.713T4.75 15m4.425 0h1.5q.425 0 .713-.288t.287-.712v-1.5q0-.425-.288-.712t-.712-.288h-1.25v-1.25h1q0 .2.15.35t.35.15h.125q.275 0 .45-.175t.175-.45V10q0-.425-.288-.712T10.676 9h-1.5q-.425 0-.712.288T8.175 10v1.5q0 .425.288.713t.712.287h1.25v1.25h-1q0-.2-.15-.35t-.35-.15H8.8q-.275 0-.45.175t-.175.45V14q0 .425.288.713t.712.287m4.4-1.5v-3h1v3zm-.25 1.5h1.5q.425 0 .713-.288t.287-.712v-4q0-.425-.287-.712T14.825 9h-1.5q-.425 0-.712.288t-.288.712v4q0 .425.288.713t.712.287m4.425-2.625l.85 2.225q.075.2.225.3t.35.1h.2q.275 0 .45-.175t.175-.45v-4.75q0-.275-.175-.45T19.375 9t-.45.175t-.175.45v2L17.9 9.4q-.075-.2-.225-.3t-.35-.1h-.2q-.275 0-.45.175t-.175.45v4.75q0 .275.175.45t.45.175t.45-.175t.175-.45zM3 20q-.825 0-1.412-.587T1 18V6q0-.825.588-1.412T3 4h18q.825 0 1.413.588T23 6v12q0 .825-.587 1.413T21 20z"
      />
    </SVGIcon>
  );
}
